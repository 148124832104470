import { Component, Vue, Prop } from "vue-property-decorator";
import { Profile } from "@/types/profile";
import { profileModule } from "@store/namespaces";
import { ProfileActions } from "@store/modules/profile/types";
import { formatPhoneNumber } from "@helpers";

@Component({
  components: {
    PhoneNumberDialog: () =>
      import("@/components/PhoneNumberDialog/PhoneNumberDialog.vue"),
  },
})
export default class PhoneNumberField extends Vue {
  @profileModule.Action("updateProfile")
  private readonly updateProfileAction!: ProfileActions["updateProfile"];

  @Prop({ type: Object }) private readonly phone?: Profile["phone"];

  private dialogPhoneNumber = false;
  private dialogConfirmDeletePhoneNumber = false;
  private deletingPhoneNumber = false;

  private get formatedPhoneNumber() {
    if (this.phone?.country && this.phone.country && this.phone.prefix) {
      return formatPhoneNumber(this.phone);
    }
  }

  private toggleDialogPhoneNumber(showed = !this.dialogPhoneNumber) {
    this.dialogPhoneNumber = showed;
  }

  private toogleDialogConfirmDeletePhoneNumber(
    showed = !this.dialogConfirmDeletePhoneNumber
  ) {
    this.dialogConfirmDeletePhoneNumber = showed;
  }

  private async onConfirmDeletePhoneNumber() {
    if (this.deletingPhoneNumber) return;

    this.deletingPhoneNumber = true;

    try {
      await this.updateProfileAction({
        phone: {},
      });

      this.deletingPhoneNumber = false;
      this.toogleDialogConfirmDeletePhoneNumber(false);
    } catch {
      this.deletingPhoneNumber = false;
    }
  }

  private onUpdatedPhoneNumber() {
    this.dialogPhoneNumber = false;
  }
}
