var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-right"},[(!_vm.formatedPhoneNumber)?_vm._t("button",null,{"toggleDialogPhoneNumber":_vm.toggleDialogPhoneNumber}):_c('div',{staticClass:"d-flex align-center justify-end"},[(!_vm.$vuetify.breakpoint.mobile)?_c('v-tooltip',{attrs:{"open-on-focus":false,"open-on-click":_vm.$vuetify.breakpoint.mdAndDown,"open-on-hover":_vm.$vuetify.breakpoint.lgAndUp,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.phone.approved)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","x-small":"","color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-check-circle ")])],1):_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.toggleDialogPhoneNumber(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-alert-circle ")])],1)]}}],null,false,1136418957)},[_c('span',[_vm._v(" "+_vm._s(_vm.phone.approved ? _vm.$vuetify.lang.t("$vuetify.dashboard.phone_number.approved") : _vm.$vuetify.lang.t("$vuetify.dashboard.phone_number.verify_phone"))+" ")])]):[(_vm.phone.approved)?_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","x-small":"","color":"success"}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.mobile ? 16 : 20}},[_vm._v(" mdi-check-circle ")])],1):_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.toggleDialogPhoneNumber(true)}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.mobile ? 16 : 20}},[_vm._v(" mdi-alert-circle ")])],1)],_c('span',{class:[
        'grey--text text--darken-1 flex-grow-0  cursor-pointer',
        {
          'text-caption-1 flex-shrink-0': !_vm.$vuetify.breakpoint.mobile,
          'text-caption text-truncate flex-shrink-1':
            _vm.$vuetify.breakpoint.mobile,
        },
      ],on:{"click":function($event){return _vm.toggleDialogPhoneNumber(true)}}},[_vm._v(" "+_vm._s(_vm.formatedPhoneNumber)+" ")]),_c('v-btn',{class:{
        'ml-1': _vm.$vuetify.breakpoint.mobile,
        'ml-2': !_vm.$vuetify.breakpoint.mobile,
      },attrs:{"loading":_vm.deletingPhoneNumber,"icon":"","small":!_vm.$vuetify.breakpoint.mobile,"x-small":_vm.$vuetify.breakpoint.mobile,"color":"error"},on:{"click":function($event){return _vm.toogleDialogConfirmDeletePhoneNumber(true)}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.mobile ? 16 : 20}},[_vm._v(" mdi-close-circle-outline ")])],1),(_vm.phone.approved)?_c('v-btn',{class:{
        'ml-1': _vm.$vuetify.breakpoint.mobile,
        'ml-2': !_vm.$vuetify.breakpoint.mobile,
      },attrs:{"icon":"","small":!_vm.$vuetify.breakpoint.mobile,"x-small":_vm.$vuetify.breakpoint.mobile,"color":"grey"},on:{"click":function($event){return _vm.toggleDialogPhoneNumber(true)}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.mobile ? 16 : 20}},[_vm._v(" $edit ")])],1):_vm._e()],2),_c('PhoneNumberDialog',{attrs:{"phone-number":_vm.phone},on:{"updated:phone-number":function($event){return _vm.$emit('updated:phone-number', $event)}},model:{value:(_vm.dialogPhoneNumber),callback:function ($$v) {_vm.dialogPhoneNumber=$$v},expression:"dialogPhoneNumber"}}),_c('ConfirmDialog',{attrs:{"loading":_vm.deletingPhoneNumber},on:{"confirm":_vm.onConfirmDeletePhoneNumber},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$vuetify.lang.t(
            '$vuetify.dashboard.phone_number.sure_want_unbind',
            `<span class='text-no-wrap'>${_vm.formatedPhoneNumber}</span>`
          )
        )}})]},proxy:true}]),model:{value:(_vm.dialogConfirmDeletePhoneNumber),callback:function ($$v) {_vm.dialogConfirmDeletePhoneNumber=$$v},expression:"dialogConfirmDeletePhoneNumber"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }